// import {} from 'react-bootstrap';
import {Button,Input,Grid,GridColumn,Image,Form} from 'semantic-ui-react';
import React,{useState} from 'react'
// import axios from 'axios';
import { toast } from "react-toastify";
import Qs from "qs";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './Landing.scss';

function Formulario () {
  const [formData, setformData] = useState("");
  // const onChange = e =>{
  //   setformData({
  //     ...formData,
  //     [e.target.name]: e.target.value
  //   });
  // }
     const HOST = process.env.HOST || "http://localhost:8080"
    //  const HOST = process.env.HOST || "https://restserver-323001.uw.r.appspot.com"
  
  const onSubmit = (e) =>{
    // console.log(formData);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(formData);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(HOST+"/api/webpage/landing-english", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
        e.target.reset();
    // try {
    //   const response = await fetch(HOST + "/api/webpage/landing-english", {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Accept':       'application/json',
    //     },
    //     method: "POST",
    //     body:  JSON.stringify(formData)
    //   })
    
    //   window.location.reload();
    //   toast.success("Information sended");
      
    //   if(response.status === 404) 
    //     throw {message: "Bad request"}
        
        
    //   if(response.status !== 200) 
    //     throw new Error()
  
  
    //   // Si la tarea se ha cumplido correctamente 
    //   // podemos borrar al usuario de la tabla
  
  
    // }
    // catch(e) {
    //   toast.warning("ERROR: sending the information...");
    // }
    //   var str = Qs.stringify(formData)
    //   e.preventDefault();
    //   fetch('https://docs.google.com/forms/u/0/d/e/1FAIpQLScDTg_jkiRYgsMDMNwL3Gniez6EolTOlqrJTZRlQWBSCBAflA/formResponse', {
    //   method: 'post',
    //   mode:'no-cors',
    //   headers: {'Content-Type':'application/x-www-form-urlencoded'},
    //   body: str,
    //   redirect: 'follow'
    // });
    // e.target.reset();
    // toast.success("Your answers were sent");
  };

  const resetForm =()=>{
    setformData(initialValueForm());
    console.log("reset form");
  }

  return(
 
    <div className="contenedor"> 
    <header className = "App-header-landing">
    <div className = "landing-container">
    <Grid container columns={2}>
    <Grid.Column width={12}>
    <img src = {'https://firebasestorage.googleapis.com/v0/b/landingcomligo.appspot.com/o/ImagenesLandingComligo%2Flogin%20page%20logo.png?alt=media&token=3a2c1909-73d6-4eb3-8943-216c84ae71ba'} className = "logotipo"></img>       
    </Grid.Column>
  </Grid>
    <Grid stackable columns={2}> 
    <Grid.Column  width={2}>
    </Grid.Column>
    <Grid.Column  width={6}>
    <div className="izq">

    <Form onSubmit={onSubmit} >
                  <fieldset>                  
                      <p className="conocer-titulo">Want To Know More About Us?</p>
                      <p className="detalles">Please Leave Your Details Here</p>
                      <br></br>
                      <br></br>
                        <input
                          type = "text"                         
                          placeholder = "First name*"
                          className="tres"
                          onChange={e => setformData({...formData, firstname: e.target.value})}
                        />
                        <br></br>
                        <br></br>
                        <br></br>
                        <input type = "text" 
                        placeholder = "Last name*" required 
                        className="tres"
                        onChange={e => setformData({...formData, lastname: e.target.value})}
                        />
                        <br></br>
                        <br></br>
                        <br></br>
                        <input type = "text" 
                        placeholder = "Company"
                        className="tres"
                        onChange={e => setformData({...formData, company: e.target.value})}
                        />
                        <br></br>
                        <br></br>
                        <br></br>
                         <input type = "email" 
                         placeholder = "Email*" 
                         required
                         className="tres"
                         onChange={e => setformData({...formData, email: e.target.value})}
                         />
                          <br></br>
                        <br></br>
                        <br></br>
                         <input type = "text" 
                          placeholder = "Phone*" required
                          className="tres"
                          onChange={e => setformData({...formData, phone: e.target.value})}
                          />
                         <br></br>
                        <br></br>
                        <br></br>
                         <input type = "text" 
                          placeholder = "What Spanish course are you interested in?" required
                          className="tres"
                          onChange={e => setformData({...formData, course: e.target.value})}
                          />
  
                  </fieldset>
                  <Button type = "submit" className = "landing-envio">SUBMIT</Button>
                </Form>               
     </div>
    </Grid.Column>
    <Grid.Column  width={6} >
     <div className="der">

     <ul className="listado-landing">
                    <li>
                       <p className = "parr-uno">
                         We teach Spanish, and only Spanish, through a unique platform designed by
                          native Spanish linguists
                       </p>                                        
                    </li>
                    <li>
                       <p className = "parr-dos">
                      Our matchless Spanish course offering
                          goes from general to specialization, 
                         all following CEFR and ACTFL guidelines
                       </p>
                    </li>
                    <li>
                       <p className = "parr-tres">
                       We take special care to include
                        (inter)cultural elements in ALL our courses
                       </p>
                    </li>
                    <li>
                       <p className = "parr-cuatro">
                       Our faculty is monitored at all times and
                          follows a permanent training plan on
                          teaching systematization  
                       </p>
                    </li>
                  </ul>        

   
                </div> 
    </Grid.Column>
    <Grid.Column  width={4}>
    </Grid.Column>
  </Grid>
    </div>
  </header>
  </div> 

  );
  
}

function initialValueForm (){
  return{
    firstname:"",
    lastname: "",
    company:"",
    email:"",
    phone:"",
    course:""
  }
}  

export default Formulario;